import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import route from "./routes";

const AppRouter = ()  => {
    return (
        <Router>
            <Routes>
                {route.routes.map((route, index) => (
                    <Route key={index} path={route.path} element={
                        route.element
                    }/>
                ))}
            </Routes>
        </Router>
    );
};


export default AppRouter;
