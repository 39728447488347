import React from 'react';
import "./App.css";
import Router from "./AppRouter";
import { ThemeProvider } from './components/hooks/ThemeContext';
import { LangProvider } from './components/hooks/LangContext';

function App() {
    
    return (
        <ThemeProvider>
            <LangProvider>
                <Router />
            </LangProvider>
        </ThemeProvider>
    );
}

export default App;
