import { CircularProgress } from "react-cssfx-loading";
import styled from "styled-components";

const Loading = ({ position="relative", height="100vh" }) => {

    const Container = styled.div`
        position: ${props => props.position || "relative"};
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: ${props => props.height || "100vh"};
    `;

    return (
        <Container height={height} position={position} > 
            <CircularProgress color="#A84AC9" width="100px" height="100px" duration="2s" />
        </Container>
    );
}



export default Loading;