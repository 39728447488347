import React, { lazy, Suspense } from "react";
import contentServicesES from "./content/es/services";
import contentServicesEN from "./content/en/services";
import contentPlanES from "./content/es/plans";
import contentPlanEN from "./content/en/plans";
import contentES from "./content/es/content";
import contentEN from "./content/en/content";
import Loading from './components/common/Loading.jsx';

const Home = lazy(() => import("./pages/Home"));
const Service = lazy(() => import("./pages/Service"));
const Planes = lazy(() => import("./pages/Planes"));
const RappidLinks = lazy(() => import("./pages/RappidLinks"));
const ErrorPage = lazy(() => import("./pages/404"));
const Blog = lazy(() => import("./pages/Blog"));
const Login = lazy(() => import("./pages/Login"));
const CreatePost = lazy(() => import("./pages/CreatePost"));


const route = {
    routes: [
        { 
            path: "/", 
            element: 
                <Suspense fallback={<Loading />}>
                    <Home />
                </Suspense> },
        { 
            path: "/diseño-web", 
            element: 
                <Suspense fallback={<Loading />}>
                    <Service 
                        service="diseño" 
                        contentES={contentES} 
                        contentEN={contentEN} 
                        contentServicesES={contentServicesES} 
                        contentServicesEN={contentServicesEN} 
                    />
                </Suspense> },
        { 
            path: "/desarrollo-web", 
            element: 
                <Suspense fallback={<Loading />}>
                    <Service 
                        service="desarrollo" 
                        contentES={contentES} 
                        contentEN={contentEN} 
                        contentServicesES={contentServicesES} 
                        contentServicesEN={contentServicesEN} 
                    />
                </Suspense> },
        { 
            path: "/automatizacion", 
            element: 
                <Suspense fallback={<Loading />}>
                    <Service 
                        service="automatizacion" 
                        contentES={contentES} 
                        contentEN={contentEN} 
                        contentServicesES={contentServicesES} 
                        contentServicesEN={contentServicesEN} 
                    />
                </Suspense> },
        { 
            path: "/planes", 
            element: 
                <Suspense fallback={<Loading />}>
                    <Planes 
                        contentES={contentPlanES} 
                        contentEN={contentPlanEN}/>
                </Suspense> },
        { 
            path: "/rappid-links", 
            element: 
                <Suspense fallback={<Loading />}>
                    <RappidLinks />
                </Suspense> },
        {
            path: "/blog",
            element: 
                <Suspense fallback={<Loading />}>
                    <Blog 
                        contentES={contentES} 
                        contentEN={contentEN}/>
                </Suspense>},
        {
            path: "/blog/:id",
            element: 
                <Suspense fallback={<Loading />}>
                    <Blog 
                        contentES={contentES} 
                        contentEN={contentEN}/>
                </Suspense>},
        {
            path: "/login",
            element: 
                <Suspense fallback={<Loading />}>
                    <Login 
                        contentES={contentES} 
                        contentEN={contentEN}/>
                </Suspense>},
        {
            path: "/create-post",
            element: 
                <Suspense fallback={<Loading />}>
                    <CreatePost />
                </Suspense>},
        { 
            path: "*", 
            element: 
                <Suspense fallback={<Loading />}>
                    <ErrorPage />
                </Suspense> }
    ],
};

export default route;