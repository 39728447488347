import React, { createContext, useState, useContext, useEffect } from 'react';

const LangContext = createContext();

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState(localStorage.getItem('Lang') || 'es');

    useEffect(() => {
        localStorage.setItem('Lang', lang);
    }, [lang]);

    const toggleLang = () => {
        setLang((prevLang) => (prevLang === 'en' ? 'es' : 'en'));
    };

    return (
        <LangContext.Provider value={{ lang, toggleLang }}>
            {children}
        </LangContext.Provider>
    );
};

export const useLang = () => useContext(LangContext);
