// Navbar
import logoBlack from "../../static/Vector.png";
import logoWhite from "../../static/Vector-white.png";
import logoDark from "../../static/logo-dark.svg";
import light from "../../static/light-theme.svg";
import dark from "../../static/dark-theme.svg";
import lightHover from "../../static/light-theme-hover.svg";
import darkHover from "../../static/dark-theme-hover.svg";

// Front
import portada from "../../static/portada.png";
import fondoPortada from "../../static/fondo-portada.png";
import fondoPortadaDark from "../../static/fondo-portada-dark.png";

// Cards
import behanceDark from "../../static/behance-dark.png";
import githubDark from "../../static/github-dark.png";
import behanceDarkHover from "../../static/behance-dark-hover.png";
import githubDarkHover from "../../static/github-dark-hover.png";
import behancePink from "../../static/behance.svg";
import githubPink from "../../static/github.svg";
import desarrollo from "../../static/desarrollo-icon.svg";
import trading from "../../static/trading-icon.svg";
import diseño from "../../static/diseño-icon.svg";
import automatizacion from "../../static/automatizacion-icon.svg";
import mockup1 from "../../static/mockup1.png";
import mockup2 from "../../static/mockup2.png";
import mockup3 from "../../static/mockup3.png";

// Team
import teamPicture from "../../static/team.png";
import shape1 from "../../static/shape1.png";
import shape2 from "../../static/shape3.png";
import shape3 from "../../static/shape2.png";

// Social
import linkedin from "../../static/linkedin.png";
import behance from "../../static/behance.png";
import instagram from "../../static/instagram.png";
import tiktok from "../../static/tiktok.png";
import github from "../../static/github.png";
import email from "../../static/email.png";
import whatsapp from "../../static/whatsapp.png";

import linkedinDarkFooter from "../../static/linkedin-dark-footer.png";
import behanceDarkFooter from "../../static/behance-dark-footer.png";
import instagramDarkFooter from "../../static/instagram-dark-footer.png";
import tiktokDarkFooter from "../../static/tiktok-dark-footer.png";
import githubDarkFooter from "../../static/github-dark-footer.png";
import emailDarkFooter from "../../static/email-dark-footer.png";
import whatsappDarkFooter from "../../static/whatsapp-dark-footer.png";

const content = {
    helmet: {
        title: "Web Development and Website Design | Professional Solutions at RappidTech",
        description: "Explore our Web Development and Website Design services at RappidTech. We offer professional and customized solutions to elevate your brand online.",        
        canonical: "https://rappidtech.com/",
        language: "en",
        author: "RappidTech",
        robots: "index, follow",
        publisher: "RappidTech",
    },    
    navBar: {
        logo: {
            black: logoBlack,
            white: logoWhite,
            dark: logoDark,
        },
        links: [
            {
                name: "Services",
                url: "/#servicios",
                isInternalSection: true,
                subLinks: [
                    {
                        name: "Development",
                        url: "/desarrollo-web",
                        isInternalSection: false,
                    },
                    {
                        name: "UX/UI Design",
                        url: "/diseño-web",
                        isInternalSection: false,
                    },
                    {
                        name: "Trading",
                        url: "https://tradingsolutions.ar/",
                        isExternal: true,
                    },
                    {
                        name: "Automation",
                        url: "/automatizacion",
                        isInternalSection: false,
                    },
                ],
            },
            // { name: "Products",
            //     url: "/#productos",
            //     isInternalSection: true,
            //     subLinks: [
            //         { name: "Rappid Links", url: "/rappid-links", isInternalSection: false },
            //     ]
            // },
            { name: "Plans", url: "/planes", isInternalSection: false },
            { name: "Portfolio", url: "/#portfolio", isInternalSection: true },
            { name: "About Us", url: "/#nosotros", isInternalSection: true },
            {
                name: "Contact",
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios",
                isExternal: true,
            },
        ],
        theme: {
            light: light,
            dark: dark,
            lightHover: lightHover,
            darkHover: darkHover,
        },
    },
    frontPage: {
        title: "In our design agency, we transform your ideas into impactful digital experiences that captivate your audience from the first click.",
        paragraph:
            "With the help of our web design agency, you can stand out with a unique site that reflects the essence of your brand.",
        portada: portada,
        fondoPortada: fondoPortada,
        fondoPortadaDark: fondoPortadaDark,
    },
    titleServicios: "Our software development services",
    cardsContent: [
        {
            title: "Web and Mobile Development",
            description:
                "We specialize in web and mobile development, offering responsive applications and websites optimized for an exceptional user experience on all devices.",
            img: desarrollo,
            textButton: "Explore",
            url: "/development",
        },
        {
            title: "UX/UI Design",
            description:
                "In our agency, UX/UI design is a priority, as we understand the importance of creating intuitive and attractive interfaces that enhance the user experience and maximize retention and conversion.",
            img: diseño,
            textButton: "Explore",
            url: "/design",
        },
        {
            title: "Trading",
            description:
                "With precise analysis and solid strategies, we provide you with the tools to make informed decisions and maximize your potential in every financial move.",
            img: trading,
            textButton: "Explore",
            url: "https://tradingsolutions.ar/",
        },
        {
            title: "Process Automation",
            description:
                "Optimize your workflow and free up your time for what really matters. From repetitive tasks to complex processes, we transform your processes into smooth, uninterrupted operations.",
            img: automatizacion,
            textButton: "Explore",
            url: "/automation",
        },
    ],
    titleTecnologias: "For the creation of creative, dynamic, and self-administrable web pages, we use the best technologies:",
    tech: [
        "JavaScript",
        "TypeScript",
        "React",
        "Node.js",
        "Express",
        "HTML",
        "CSS",
        "Bootstrap",
        "Tailwind",
        "Material-UI",
        "GitHub",
        "Firebase",
        "Google Cloud",
        "Wordpress",
        "Figma",
        "PineScript",
        "TradingView",
    ],
    infoCard: {
        title: "Visit our gallery of web pages",
        description:
            "Browse our custom web pages. Visit our Behance profile for a detailed look at our tailor-made web pages.",
        textButtonPortfolio: "Portfolio",
        textButtonGitHub: "GitHub",
        behanceLight: behancePink,
        githubLight: githubPink,
        behanceLightHover: behance,
        githubLightHover: github,
        behanceDark: behanceDark,
        githubDark: githubDark,
        behanceDarkHover: behanceDarkHover,
        githubDarkHover: githubDarkHover,
        urlBehance: "https://www.behance.net/rappidtech",
        urlGitHub: "https://www.github.com/rappidtech",
    },
    Mockups: [mockup1, mockup2, mockup3],
    titleIdeas: "Your dream online website come true",
    chrono: [
        {
            title: "1",
            cardTitle: "Step 1.",
            cardSubtitle: "Join the exploratory call.",
            cardDetailedText:
                "During the meeting, the client's needs are discussed, and different approaches are proposed.",
        },
        {
            title: "2",
            cardTitle: "Step 2.",
            cardSubtitle: "Team definition and solution proposal.",
            cardDetailedText:
                "Once we have all the information, a solution is proposed and communicated to the development team.",
        },
        {
            title: "3",
            cardTitle: "Step 3.",
            cardSubtitle: "Start of project development.",
            cardDetailedText:
                "Once deadlines are agreed upon, we start working, updating each stage of the project according to client feedback.",
        },
    ],
    testimonials: {
        title: "Voices that validate our commitment to work",
        data: [
            {
                name: "Laura Parker",
                country: "USA",
                testimonial:
                    "The development team of this company is simply exceptional. They helped me create a mobile application that exceeded all my expectations. Their focus on UX/UI design made the application intuitive and beautiful. I would recommend them to anyone!",
            },
            {
                name: "Ricardo Vargas",
                country: "Peru",
                testimonial:
                    "As a trader, I needed customized tools to analyze the market efficiently. This company not only created the solutions I needed but also guided me through every step of the process. Now I have a set of tools that has helped me improve my trading strategies.",
            },
            {
                name: "Emily Anderson Smith",
                country: "Canada",
                testimonial:
                    "Digital process automation was a game-changer for my company. Thanks to this company, we were able to eliminate repetitive tasks and increase efficiency in our operations. The development team is highly professional and committed!",
            },
        ],
    },
    team: {
        title: "We are experts in web pages",
        teamPicture: teamPicture,
        data: [
            {
                name: "Maxi",
                position: "Full Stack Web Developer",
            },
            {
                name: "Gonzalo",
                position: "Front End Developer",
            },
            {
                name: "Paula",
                position: "Web Design Specialist",
            },
        ],
        animate: {
            shapes: [shape1, shape2, shape3],
            start: [0, 0, 0],
            end: [320, -150, -150],
        },
        grid: [
            {
                title: "Operational Excellence",
                description:
                    "We value excellence in web development and strive to exceed expectations on every project.",
            },
            {
                title: "Diversity of Solutions",
                description:
                    "In our web design and development agency, we celebrate the diversity of solutions, adapting to each client's unique needs to deliver outstanding and personalized results.",
            },
            {
                title: "Commitment",
                description:
                    "In our web page creation agency, we are committed to providing innovative and personalized solutions that drive online success for our clients.",
            },
            {
                title: "Transparency",
                description:
                    "In our web page agency, we guarantee open and transparent communication on every project, from quotation to final delivery, so that our clients feel secure and satisfied with our services.",
            },
        ],
    },
    faqs: {
        title: "Frequently asked questions about web design and development services",
        faqs: [
            {
                title: "What web design services do you offer?",
                description: "At RappidTech, we offer a wide range of web design services, including UX/UI design, responsive web development, custom website creation, and more. Our team of experts will work with you to create a website that reflects your brand essence and engages your audience."
            },
            {
                title: "How can I start a web development project with you?",
                description: "Starting a web development project with us is easy. Simply contact us through our contact form or by phone, and we'll be happy to discuss your needs and provide you with a free initial consultation."
            },
            {
                title: "What advantages does digital process automation offer for my business?",
                description: "Digital process automation can offer numerous advantages for your business, such as workflow optimization, error reduction, time and resource savings, and improved operational efficiency. At RappidTech, we offer customized automation solutions to fit your specific needs."
            },
            {
                title: "What is the web development process you follow?",
                description: "At RappidTech, we follow a comprehensive and transparent web development process that includes initial consultations, conceptual design, development, testing, and deployment. We work closely with you at every stage to ensure that your needs and goals are successfully met."
            },
            {
                title: "How can I ensure that my website is secure and protected against cyber threats?",
                description: "The security of your website is a top priority for us. We implement the latest security measures, such as data encryption, SSL certificates, and protection against malware, to ensure the security of your site and your users' information."
            },
            {
                title: "Do you offer maintenance and support services after the launch of my website?",
                description: "Yes, at RappidTech, we offer ongoing maintenance and support services after the launch of your website. This includes regular updates, security monitoring, technical support, and more to ensure that your site runs optimally and securely in the long term."
            },
            {
                title: "Can you help me integrate my website with other platforms or external services?",
                description: "Yes, our web development team can help you integrate your website with other platforms and external services, such as payment systems, marketing tools, social media, and more. This allows you to take full advantage of the additional features and functionalities offered by these platforms."
            }
        ]
    },
    form: {
        title: "Let's talk about your website",
        description:
            "Tell us about your ideal website and we will contact you to help. We specialize in creating professional websites for businesses of all sizes, providing tailor-made solutions that stand out in the competitive digital world.",
        button: "Send",
        fields: [
            {
                name: "Name",
                placeholder: "Full name",
                type: "text",
                required: true,
            },
            {
                name: "Email",
                placeholder: "Email",
                type: "email",
                required: true,
            },
            {
                name: "Phone",
                placeholder: "Phone",
                type: "tel",
                required: false,
            },
            {
                name: "Message",
                placeholder: "Message",
                type: "textarea",
                required: true,
            },
        ],
    },
    footer: {
        social: [
            {
                url: "https://www.linkedin.com/company/rappidtech",
                icon: linkedin,
            },
            {
                url: "https://www.behance.net/rappidtech",
                icon: behance,
            },
            {
                url: "https://www.instagram.com/rappidtech/",
                icon: instagram,
            },
            {
                url: "https://www.tiktok.com/@rappidtech?_t=8jdIdUnvSLu&_r=1",
                icon: tiktok,
            },
            {
                url: "https://github.com/rappidtech",
                icon: github,
            },
            {
                url: "mailto:nosotros@rappidtech.com",
                icon: email,
            },
            {
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios",
                icon: whatsapp,
            },
        ],
        socialDark: [
            {
                url: "https://www.linkedin.com/company/rappidtech",
                icon: linkedinDarkFooter,
            },
            {
                url: "https://www.behance.net/rappidtech",
                icon: behanceDarkFooter,
            },
            {
                url: "https://www.instagram.com/rappidtech/",
                icon: instagramDarkFooter,
            },
            {
                url: "https://www.tiktok.com/@rappidtech?_t=8jdIdUnvSLu&_r=1",
                icon: tiktokDarkFooter,
            },
            {
                url: "https://github.com/rappidtech",
                icon: githubDarkFooter,
            },
            {
                url: "mailto:nosotros@rappidtech.com",
                icon: emailDarkFooter,
            },
            {
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios",
                icon: whatsappDarkFooter,
            },
        ],
        rights: "© 2024 RappidTech. All rights reserved.",
    },
    error: {
        title: "404",
        subtitle: "Page not found",
        button: "Go back to home",
    },
};

export default content;
