import desarrolloImg1 from './../../static/documentos web global/ilustraciones/11 Desarrollo - dev.svg';
import desarrolloImg2 from './../../static/documentos web global/ilustraciones/12 Desarrollo - desarrollo web.svg';
import desarrolloImg3 from './../../static/documentos web global/ilustraciones/13 Desarrollo - desarrollo movil.svg';
import desarrolloImg4 from './../../static/documentos web global/ilustraciones/14 Desarrollo - ecommerce.svg';

import diseñoImg1 from './../../static/documentos web global/ilustraciones/03 Diseño - mobile.svg';
import diseñoImg2 from './../../static/documentos web global/ilustraciones/04 Diseño - diseño web app.svg';
import diseñoImg3 from './../../static/documentos web global/ilustraciones/05 Diseño - diseño de interaccion.svg';
import diseñoImg4 from './../../static/documentos web global/ilustraciones/06 Diseño - diseño de exp de usuario ux.svg';
import diseñoImg5 from './../../static/documentos web global/ilustraciones/07 Diseño - arq de la info.svg';
import diseñoImg6 from './../../static/documentos web global/ilustraciones/08 Diseño - identidad de marca.svg';
import diseñoImg7 from './../../static/documentos web global/ilustraciones/0 Diseño - diseño responsive.svg';
import diseñoImg8 from './../../static/documentos web global/ilustraciones/09 Diseño - accesibilidad.svg';
import diseñoImg9 from './../../static/documentos web global/ilustraciones/10 Diseño - mantenimiento.svg';

import automatizacionImg1 from './../../static/documentos web global/ilustraciones/15 Automatizacion - in progress.svg';
import automatizacionImg2 from './../../static/documentos web global/ilustraciones/16 Automatizacion - process.svg';
import automatizacionImg3 from './../../static/documentos web global/ilustraciones/18 Automatizacion - data processing.svg';
import automatizacionImg4 from './../../static/documentos web global/ilustraciones/17 Automatizacion - creation process.svg';

import fondoLight from '../../static/fondo-service-light.png';
import fondoDark from '../../static/fondo-service-dark.png';

const content = {

    portada: {
        fondoLight,
        fondoDark
    },
    titleSection: "Te ofrecemos...",
    desarrollo: {
        helmet: {
            title: "Plan de desarrollo web personalizado para tu negocio online",
            description: "Explora nuestro plan de desarrollo web personalizado en RappidTech. Diseñamos soluciones a medida para tus necesidades digitales, desde sitios web elegantes hasta funcionalidades avanzadas. ¡Destaca en línea con nuestro servicio experto en desarrollo web!",
            keywords: "desarrollo web, desarrollo de aplicaciones web, desarrollo de sitios web, programación web, desarrollo frontend, desarrollo backend, desarrollo web personalizado, desarrollo web con JavaScript, desarrollo web con React, desarrollo web ágil",
            canonical: "https://rappidtech.com/desarrollo-web",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "Desarrollo Web y Móvil",
        bigTitle: "Creamos plataformas adaptativas que aseguran rendimiento y accesibilidad",
        text: "Desarrollamos plataformas web y móviles que destacan por su funcionalidad, escalabilidad y diseño, preparadas para el futuro digital.",
        sectionTitle: "Construyendo cimientos digitales robustos",
        sectionDescription: "Nuestro servicio de Desarrollo Frontend y Backend es la base de la excelencia digital. Con nuestra combinación de habilidades técnicas y experiencia estratégica, creamos sistemas que no solo funcionan, sino que también impulsan el éxito. Desde la estética hasta la funcionalidad, nuestro enfoque en el desarrollo integral es la clave para forjar una presencia digital sólida y confiable.",
        image: desarrolloImg1,
        accordion: [
            {
                title: "Front-end",
                description: "Traducimos diseños en interacciones cautivadoras. Codificamos interfaces que capturan la esencia de tu marca y brindan una experiencia inmersiva. Cada botón, cada transición, es meticulosamente diseñado para brindar una experiencia intuitiva y visualmente atractiva.",
            },
            {
                title: "Back-end",
                description: "Detrás de la escena, construimos sistemas inteligentes y robustos. Cada función, cada base de datos, cada proceso está optimizado para el rendimiento y la escalabilidad. Nuestro backend potencia la funcionalidad sin fisuras que los usuarios esperan, desde compras en línea hasta experiencias personalizadas.",
            }
        ],
        grid: [
            {
                title: "Desarrollo web",
                description: "En el mundo digital actual, la presencia en línea es fundamental. Nuestro servicio de Desarrollo Web combina la estética y la funcionalidad para crear sitios web elegantes y altamente interactivos que resuenan con tus audiencias y objetivos comerciales.",
                image: desarrolloImg2                                 
            },
            {
                title: "Desarrollo móvil",
                description: "La movilidad es la clave para llegar a los usuarios en cualquier momento y lugar. A través de nuestro servicio de Desarrollo Móvil, creamos aplicaciones adaptadas a las plataformas líderes que garantizan experiencias sin fisuras y un acceso constante a tus servicios.",
                image: desarrolloImg3                                 
            },
            {
                title: "Desarrollo eCommerce",
                description: "Nuestro servicio de Desarrollo de Ecommerce construye plataformas de compras en línea seguras y eficientes que optimizan la experiencia del cliente, impulsan las conversiones y crean relaciones duraderas con los compradores en línea.",
                image: desarrolloImg4                                 
            }
        ]
    },
    diseño: {
        helmet: {
            title: "Diseño web profesional economico para tu negocio online",
            description: "Descubre nuestro servicio de diseño web en RappidTech. Creamos sitios web profesionales y atractivos que cautivan a tu audiencia desde el primer clic. Desde la conceptualización hasta la implementación, hacemos realidad tus ideas digitales.",
            keywords: "diseño web, diseño de sitios web, desarrollo web creativo, experiencia de usuario, interfaz de usuario, diseño web responsivo, diseño web moderno, estética web, diseño de páginas web profesionales, personalización de sitios web",
            canonical: "https://rappidtech.com/diseño-web",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "Diseño UX UI",
        bigTitle: "Estrategias innovadoras para experiencias únicas",
        text: "Optimizamos cada interacción para transformar visitantes en usuarios leales. Descubre cómo nuestra expertise en UX/UI potencia tu presencia digital.",
        sectionTitle: "Crea experiencias de usuario únicas, empodera tu negocio",
        sectionDescription: "En cada línea de código y en cada trazo de diseño, creamos un vínculo entre la tecnología y las personas. No construimos sitios web o aplicaciones; creamos destinos donde los usuarios encuentran valor, facilidad y significado. Nuestros expertos en Diseño UX/UI no solo están bien versados en la estética, sino también en la psicología del usuario y en la ciencia del flujo intuitivo. Nuestra dedicación a la innovación, la estrategia y la tecnología adecuada nos permite construir experiencias que marcan la diferencia.",
        image: diseñoImg1,
        accordion: [
            {
                title: "Design Thinking",
                description: "Creamos más que interfaces; creamos momentos que perduran. Las empresas emblemáticas son aquellas que entienden el poder de las experiencias de usuario excepcionales. Estas experiencias no solo impulsan la lealtad de la marca, sino que también dan forma a un camino hacia el éxito sostenible.",
            },
            {
                title: "Prototipos interactivos",
                description: "Son representaciones funcionales y dinámicas de un diseño digital. Permiten a los equipos y a los stakeholders explorar y probar la funcionalidad y el flujo del diseño antes de avanzar a la fase de desarrollo. Son una herramienta esencial para comprender cómo los usuarios interactuarán con la interfaz, identificar posibles problemas y ajustar detalles para asegurar una experiencia fluida y atractiva. Son puentes digitales entre la visión y la realidad, impulsando el diseño hacia la excelencia.",
            },
            {
                title: "Diseño e iteración",
                description: "Nuestra filosofía de diseño e iteración se basa en un enfoque evolutivo. A medida que diseñamos, construimos y refinamos, cada paso se evalúa en función de su efectividad y contribución al producto final. Esta metodología ágil no solo ahorra tiempo y recursos, sino que también asegura que cada componente cumpla con los requisitos y añada un valor tangible. El proceso iterativo nos permite pulir constantemente cada aspecto del producto, garantizando que el resultado final esté en sintonía con la visión y las expectativas.",
            }
        ],
        grid: [
            {
                title: "Diseño web/app",
                description: "Diseñamos sitios y aplicaciones web que abarcan desde portafolios personales hasta tiendas en línea robustas. Cualquiera sea tu visión, la hacemos realidad con un diseño atractivo y funcional.",
                image: diseñoImg2                                 
            },
            {
                title: "Diseño de interacción (UI)",
                description: "Moldeamos las conexiones digitales. Nuestro enfoque en el diseño de interacción garantiza que cada toque, clic y movimiento sea deliberado y satisfactorio.",
                image: diseñoImg3                                 
            },
            {
                title: "Diseño de experiencia de usuario (UX)",
                description: "Diseñamos para cautivar a tus usuarios. Creamos interfaces que no solo se ven bien, sino que también brindan una experiencia intuitiva y gratificante en cada interacción.",
                image: diseñoImg4                                 
            },
            {
                title: "Arquitectura de la información",
                description: "Te guiamos sin esfuerzo a través de la información. Organizamos y estructuramos la información en tu sitio para que tus usuarios encuentren lo que necesitan, cuando lo necesitan.",
                image: diseñoImg5                                 
            },
            {
                title: "Identidad de marca y estrategia",
                description: "Damos vida a tus valores. Creamos identidades visuales cohesivas y estrategias de comunicación que transmiten tu esencia y resuenan con tu audiencia.",
                image: diseñoImg6                                 
            },
            {
                title: "Diseño responsive",
                description: "Mantenemos tu presencia en todas partes. Diseñamos interfaces que se adaptan a todos los dispositivos, asegurando una experiencia impecable en cualquier pantalla.",
                image: diseñoImg7                                 
            },
            {
                title: "Cumplimiento de accesibilidad (ADA)",
                description: "Creamos soluciones que cumplen con las normas de accesibilidad para que tu sitio sea utilizable por todas las personas, sin importar sus capacidades.",
                image: diseñoImg8                                 
            },
            {
                title: "Mantenimiento y gestión del sitio web",
                description: "Tu presencia, siempre al día. Nos encargamos de mantener tu sitio funcionando sin problemas, actualizado y seguro para que puedas concentrarte en lo que realmente importa: tu negocio.",
                image: diseñoImg9                                 
            },
        ]
    },
    automatizacion: {
        helmet: {
            title: "Automatización de procesos digitales para una gestión eficiente",
            description: "Optimiza tus operaciones con nuestra solución de automatización de procesos en RappidTech. Simplificamos y agilizamos tus tareas digitales para que puedas centrarte en lo que realmente importa. ¡Transforma tu negocio digital hoy mismo!",
            keywords: "automatización de procesos, automatización empresarial, procesos digitales automatizados, optimización de flujos de trabajo, eficiencia operativa, automatización de tareas, mejora de procesos, automatización de tareas repetitivas, soluciones de automatización, herramientas de automatización de procesos",
            canonical: "https://rappidtech.com/automatizacion",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "Automatización",
        bigTitle: "Automatización inteligente al alcance.",
        text: "Nuestra avanzada tecnología de automatización de procesos digitales te empodera para rediseñar el flujo de trabajo, optimizar tareas y liberar el potencial creativo de tu equipo.",
        sectionTitle: "Automatización para una Gestión Eficiente",
        sectionDescription: "En el mundo digital actual, la eficiencia es clave. Nuestros servicios de automatización optimizan tus flujos de trabajo y liberan tiempo valioso. Desde tareas rutinarias hasta sistemas complejos, creamos soluciones digitales que ahorran tiempo y recursos. Confía en nosotros para simplificar tus operaciones digitales y mejorar la precisión en cada paso del proceso.",
        image: automatizacionImg1,
        accordion: [
            {
                title: "Optimización Eficiente",
                description: "Simplificamos y automatizamos tus procesos digitales, reduciendo errores y tiempos de ejecución.",
            },
            {
                title: "Integración y Conectividad",
                description: "Integramos tus sistemas y aplicaciones, creando flujos de trabajo fluidos y una comunicación eficiente entre plataformas.",
            },
            {
                title: "Seguridad y Control",
                description: "Implementamos medidas de seguridad sólidas y ofrecemos control total sobre tus procesos automatizados.",
            }
        ],
        grid: [
            {
                title: "Automatización de procesos digitales",
                description: "Optimizamos y automatizamos tus flujos de trabajo digitales, eliminando la repetición de tareas manuales y mejorando la eficiencia de tus procesos empresariales.",
                image: automatizacionImg2                                 
            },
            {
                title: "Analítica de datos",
                description: "Transformamos tus datos en información estratégica que impulsa decisiones inteligentes en tus procesos digitales automatizados, proporcionando una ventaja competitiva en tu industria.",
                image: automatizacionImg3                                 
            },
            {
                title: "Integración y conectividad",
                description: "Creamos sistemas que conectan y sincronizan tus aplicaciones y plataformas, mejorando la colaboración y la comunicación entre tus procesos automatizados, para un rendimiento empresarial óptimo.",
                image: automatizacionImg4                                 
            }
        ]
    },

}

export default content;