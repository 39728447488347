import desarrolloImg1 from './../../static/documentos web global/ilustraciones/11 Desarrollo - dev.svg';
import desarrolloImg2 from './../../static/documentos web global/ilustraciones/12 Desarrollo - desarrollo web.svg';
import desarrolloImg3 from './../../static/documentos web global/ilustraciones/13 Desarrollo - desarrollo movil.svg';
import desarrolloImg4 from './../../static/documentos web global/ilustraciones/14 Desarrollo - ecommerce.svg';

import diseñoImg1 from './../../static/documentos web global/ilustraciones/03 Diseño - mobile.svg';
import diseñoImg2 from './../../static/documentos web global/ilustraciones/04 Diseño - diseño web app.svg';
import diseñoImg3 from './../../static/documentos web global/ilustraciones/05 Diseño - diseño de interaccion.svg';
import diseñoImg4 from './../../static/documentos web global/ilustraciones/06 Diseño - diseño de exp de usuario ux.svg';
import diseñoImg5 from './../../static/documentos web global/ilustraciones/07 Diseño - arq de la info.svg';
import diseñoImg6 from './../../static/documentos web global/ilustraciones/08 Diseño - identidad de marca.svg';
import diseñoImg7 from './../../static/documentos web global/ilustraciones/0 Diseño - diseño responsive.svg';
import diseñoImg8 from './../../static/documentos web global/ilustraciones/09 Diseño - accesibilidad.svg';
import diseñoImg9 from './../../static/documentos web global/ilustraciones/10 Diseño - mantenimiento.svg';

import automatizacionImg1 from './../../static/documentos web global/ilustraciones/15 Automatizacion - in progress.svg';
import automatizacionImg2 from './../../static/documentos web global/ilustraciones/16 Automatizacion - process.svg';
import automatizacionImg3 from './../../static/documentos web global/ilustraciones/18 Automatizacion - data processing.svg';
import automatizacionImg4 from './../../static/documentos web global/ilustraciones/17 Automatizacion - creation process.svg';

import fondoLight from '../../static/fondo-service-light.png';
import fondoDark from '../../static/fondo-service-dark.png';

const content = {
    portada: {
        fondoLight,
        fondoDark
    },
    titleSection: "We offer you...",
    desarrollo: {
        helmet: {
            title: "Plan de desarrollo web personalizado para tu negocio online",
            description: "Explora nuestro plan de desarrollo web personalizado en RappidTech. Diseñamos soluciones a medida para tus necesidades digitales, desde sitios web elegantes hasta funcionalidades avanzadas. ¡Destaca en línea con nuestro servicio experto en desarrollo web!",
            keywords: "desarrollo web, desarrollo de aplicaciones web, desarrollo de sitios web, programación web, desarrollo frontend, desarrollo backend, desarrollo web personalizado, desarrollo web con JavaScript, desarrollo web con React, desarrollo web ágil",
            canonical: "https://rappidtech.com/desarrollo-web",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "Web and Mobile Development",
        bigTitle: "We create adaptive platforms that ensure performance and accessibility",
        text: "We develop web and mobile platforms that stand out for their functionality, scalability, and design, prepared for the digital future.",
        sectionTitle: "Building robust digital foundations",
        sectionDescription: "Our Frontend and Backend Development service is the basis of digital excellence. With our combination of technical skills and strategic experience, we create systems that not only work but also drive success. From aesthetics to functionality, our focus on comprehensive development is key to forging a solid and reliable digital presence.",
        image: desarrolloImg1,
        accordion: [
            {
                title: "Front-end",
                description: "We translate designs into captivating interactions. We code interfaces that capture the essence of your brand and provide an immersive experience. Every button, every transition, is meticulously designed to offer an intuitive and visually appealing experience.",
            },
            {
                title: "Back-end",
                description: "Behind the scenes, we build intelligent and robust systems. Each function, each database, each process is optimized for performance and scalability. Our backend powers the seamless functionality users expect, from online shopping to personalized experiences.",
            }
        ],
        grid: [
            {
                title: "Web development",
                description: "In today's digital world, online presence is fundamental. Our Web Development service combines aesthetics and functionality to create elegant and highly interactive websites that resonate with your audiences and business goals.",
                image: desarrolloImg2                                 
            },
            {
                title: "Mobile development",
                description: "Mobility is key to reaching users anytime, anywhere. Through our Mobile Development service, we create applications tailored to the leading platforms that guarantee seamless experiences and constant access to your services.",
                image: desarrolloImg3                                 
            },
            {
                title: "Ecommerce development",
                description: "Our Ecommerce Development service builds secure and efficient online shopping platforms that optimize the customer experience, drive conversions, and create lasting relationships with online shoppers.",
                image: desarrolloImg4                                 
            }
        ]
    },
    diseño: {
        helmet: {
            title: "Diseño web profesional economico para tu negocio online",
            description: "Descubre nuestro servicio de diseño web en RappidTech. Creamos sitios web profesionales y atractivos que cautivan a tu audiencia desde el primer clic. Desde la conceptualización hasta la implementación, hacemos realidad tus ideas digitales.",
            keywords: "diseño web, diseño de sitios web, desarrollo web creativo, experiencia de usuario, interfaz de usuario, diseño web responsivo, diseño web moderno, estética web, diseño de páginas web profesionales, personalización de sitios web",
            canonical: "https://rappidtech.com/diseño-web",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "UX UI Design",
        bigTitle: "Innovative strategies for unique experiences",
        text: "We optimize every interaction to transform visitors into loyal users. Discover how our expertise in UX/UI boosts your digital presence.",
        sectionTitle: "Create unique user experiences, empower your business",
        sectionDescription: "In every line of code and every design stroke, we create a link between technology and people. We don't just build websites or applications; we create destinations where users find value, ease, and meaning. Our UX/UI Design experts are not only versed in aesthetics but also in user psychology and the science of intuitive flow. Our dedication to innovation, strategy, and the right technology allows us to build experiences that make a difference.",
        image: diseñoImg1,
        accordion: [
            {
                title: "Design Thinking",
                description: "We create more than interfaces; we create moments that last. Iconic businesses are those that understand the power of exceptional user experiences. These experiences not only drive brand loyalty but also shape a path towards sustainable success.",
            },
            {
                title: "Interactive prototypes",
                description: "Functional and dynamic representations of a digital design. They allow teams and stakeholders to explore and test the design's functionality and flow before moving forward to the development phase. They are an essential tool for understanding how users will interact with the interface, identifying potential issues, and adjusting details to ensure a smooth and engaging experience. They are digital bridges between vision and reality, driving design towards excellence.",
            },
            {
                title: "Design and iteration",
                description: "Our design and iteration philosophy is based on an evolutionary approach. As we design, build, and refine, each step is evaluated for its effectiveness and contribution to the final product. This agile methodology not only saves time and resources but also ensures that each component meets the requirements and adds tangible value. The iterative process allows us to constantly polish every aspect of the product, ensuring that the final result is in tune with the vision and expectations.",
            }
        ],
        grid: [
            {
                title: "Web/app design",
                description: "We design websites and web applications ranging from personal portfolios to robust online stores. Whatever your vision, we make it a reality with attractive and functional design.",
                image: diseñoImg2                                 
            },
            {
                title: "Interaction design (UI)",
                description: "We shape digital connections. Our focus on interaction design ensures that every touch, click, and movement is deliberate and satisfying.",
                image: diseñoImg3                                 
            },
            {
                title: "User experience design (UX)",
                description: "We design to captivate your users. We create interfaces that not only look good but also provide an intuitive and rewarding experience in every interaction.",
                image: diseñoImg4                                 
            },
            {
                title: "Information architecture",
                description: "We effortlessly guide you through information. We organize and structure the information on your site so that your users find what they need when they need it.",
                image: diseñoImg5                                 
            },
            {
                title: "Brand identity and strategy",
                description: "We bring your values to life. We create cohesive visual identities and communication strategies that convey your essence and resonate with your audience.",
                image: diseñoImg6                                 
            },
            {
                title: "Responsive design",
                description: "We keep your presence everywhere. We design interfaces that adapt to all devices, ensuring a flawless experience on any screen.",
                image: diseñoImg7                                 
            },
            {
                title: "Accessibility compliance (ADA)",
                description: "We create solutions that comply with accessibility standards so that your site is usable by all people, regardless of their abilities.",
                image: diseñoImg8                                 
            },
            {
                title: "Website maintenance and management",
                description: "Your presence, always up-to-date. We take care of keeping your site running smoothly, updated, and secure so you can focus on what really matters: your business.",
                image: diseñoImg9                                 
            },
        ]
    },
    automatizacion: {
        helmet: {
            title: "Automatización de procesos digitales para una gestión eficiente",
            description: "Optimiza tus operaciones con nuestra solución de automatización de procesos en RappidTech. Simplificamos y agilizamos tus tareas digitales para que puedas centrarte en lo que realmente importa. ¡Transforma tu negocio digital hoy mismo!",
            keywords: "automatización de procesos, automatización empresarial, procesos digitales automatizados, optimización de flujos de trabajo, eficiencia operativa, automatización de tareas, mejora de procesos, automatización de tareas repetitivas, soluciones de automatización, herramientas de automatización de procesos",
            canonical: "https://rappidtech.com/automatizacion",
            language: "es",
            autor: "RappidTech",
            robots: "index, follow",
            publisher: "RappidTech",
        },
        pageTitle: "Automation",
        bigTitle: "Smart automation within reach.",
        text: "Our advanced digital process automation technology empowers you to redesign workflow, optimize tasks, and unleash your team's creative potential.",
        sectionTitle: "Automation for Efficient Management",
        sectionDescription: "In today's digital world, efficiency is key. Our automation services optimize your workflows and free up valuable time. From routine tasks to complex systems, we create digital solutions that save time and resources. Trust us to simplify your digital operations and enhance accuracy at every step of the process.",
        image: automatizacionImg1,
        accordion: [
            {
                title: "Efficient Optimization",
                description: "We simplify and automate your digital processes, reducing errors and execution times.",
            },
            {
                title: "Integration and Connectivity",
                description: "We integrate your systems and applications, creating smooth workflows and efficient communication between platforms.",
            },
            {
                title: "Security and Control",
                description: "We implement solid security measures and offer total control over your automated processes.",
            }
        ],
        grid: [
            {
                title: "Digital process automation",
                description: "We optimize and automate your digital workflows, eliminating the repetition of manual tasks and improving the efficiency of your business processes.",
                image: automatizacionImg2                                 
            },
            {
                title: "Data analytics",
                description: "We turn your data into strategic information that drives intelligent decisions in your automated digital processes, providing a competitive edge in your industry.",
                image: automatizacionImg3                                 
            },
            {
                title: "Integration and connectivity",
                description: "We create systems that connect and synchronize your applications and platforms, improving collaboration and communication between your automated processes, for optimal business performance.",
                image: automatizacionImg4                                 
            }
        ]
    },

}

export default content;
